// @angular/material overrides

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $cb-accent;
}
mat-input-container.cb-time div.mat-datepicker-input-container input {
    width: 100% !important;
    height: 100% !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-form-field .mat-mdc-form-field-required-marker {
    color: $cb-error !important;
    margin-left: 2px;
}

.mat-stroked-button.mat-primary {
    background-color: rgba($cb-green, 0.1);
}

.mat-stroked-button.mat-accent {
    background-color: rgba($cb-accent, 0.1);
}

.mat-stroked-button.mat-warn {
    background-color: rgba($cb-warn, 0.1);
}

.mat-mdc-icon-button {
    width: 40px !important;
    height: 40px !important;
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f !important;
    display: inline-flex! important;
    justify-content: center !important;
    align-items: center !important;
}

.mat-mdc-tab-header-pagination-after,
.mat-mdc-tab-header-pagination-before {
    position: absolute !important;
    background-color: white;
    height: 100%;
}

.mat-mdc-tab {
    min-width: unset !important;
}

.mat-mdc-tab:first-of-type {
    margin-left: 36px;
}

.mat-mdc-tab:last-of-type {
    margin-right: 36px;
}

.mat-mdc-tab-header-pagination-after {
    right: 25px !important;
}

.mat-mdc-tab-header-pagination-before {
    left: 0 !important;
}

.mat-mdc-tab-header {
    margin-right: 0 !important;
}

.mat-mdc-tab-body-wrapper {
    display: unset !important;
    overflow: unset !important;
}

.required:after {
    content: " *";
    color: rgb(221, 44, 0) !important;
    font-size: 13px;
    font-weight: bold;
    vertical-align: top;
}

.mat-autocomplete-panel .mat-option-text,
.mat-select-panel .mat-option-text {
    font-size: 14px;
}

.mat-table .mat-row {
    min-height: 49px;
    max-height: 49px;
    height: 49px;
}

.mat-table .mat-header-row {
    min-height: 36px;
    max-height: 36px;
    height: 36px;
}

.mat-icon{
    overflow: unset !important;
}

::ng-deep mat-expansion-panel-header-title {
    flex-basis: unset !important;
}

// Hide unused tabs
.hidden-tab-header .mat-mdc-tab-header {
    display: none;
}